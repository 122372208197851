.container {
  margin: 5px 0;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.containerDetails {
  cursor: pointer;
}
.img {
  cursor: pointer;
  aspect-ratio: 1/1;
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.1);
}
.heartIcon {
  width: 25px;
  margin: 8px 0;
}

.title {
  font-size: 14px;
  color: #f2f2f2;
  overflow: hidden;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-transform: uppercase;
  -webkit-box-orient: vertical;
  font-family: 'Metropolis-bold';
}
.desc {
  font-size: 8px;
  color: #f2f2f2;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-transform: uppercase;
  font-family: 'Metropolis';
  -webkit-box-orient: vertical;
}
.price {
  font-size: 24px;
  color: #cdd3d6;
  font-weight: 600;
  font-family: 'Metropolis-bold';
}

/* Ajustes para mostrar como lista vertical */
.containerList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

/* Ajuste para el texto del producto */
.productName, .productPrice {
  font-size: 12px;
  color: #f2f2f2;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Metropolis-bold';
}


/* Ajusta el precio del producto */
.productPrice {
  font-size: 12px; /* Tamaño reducido */
  color: #cdd3d6;
  font-weight: 600;
  font-family: 'Metropolis-bold';
}


