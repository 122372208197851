.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
.category {
  font-size: 18px;
  color: #f2f2f2;
  font-family: 'Metropolis-bold';
}
