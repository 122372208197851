.container {
  text-align: center;
}

.container img {
  width: 100%;
  max-width: 216px;
  aspect-ratio: 1/1;
  max-height: 216px;
}

.container .title {
  font-size: 32px;
  color: #f2f2f2;
  font-family: 'Metropolis-Bold';
}

.container .desc {
  color: #f2f2f2;
  font-size: 14px;
  font-family: 'Metropolis';
  padding: 0 0.3em;
}

.container .desc span {
  display: block;
}
