.container {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}
.container .iconMenu {
  font-size: 24px;
  cursor: pointer;
  margin: 0px 5px;
  color: rgba(255, 255, 255, 0.75);
}

.searching {
  width: 100%;
}
