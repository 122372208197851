.container {
  border-radius: 8px;
  margin-bottom: 1.5rem;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.title {
  font-size: 18px;
  color: #f2f2f2;
  line-height: 32px;
  margin-bottom: 1rem;
  font-family: 'Metropolis';
}

.desc {
  font-size: 10px;
  color: #f2f2f2;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-transform: uppercase;
  font-family: 'Metropolis';
  -webkit-box-orient: vertical;
}

.price {
  font-size: 20px;
  color: #cdd3d6;
  font-weight: 600;
  font-family: 'Metropolis-bold';
}

.img {
  width: 100%;
  cursor: pointer;
  max-width: 150px;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 8px;
}
