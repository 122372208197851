.header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: 'Metropolis';
}

.header .logo {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 2rem;
}

.header .greetings {
  color: #f2f2f2;
  line-height: 20px;
}
.header .greetings h4 {
  margin: 0;
  font-weight: 800;
  font-size: 18px !important;
}
.header .greetings p {
  margin: 0;
  font-weight: 400;
  font-size: 18px !important;
}
