.searchIcon {
  margin: 0px;
  padding: 4px;
  cursor: pointer;
  font-size: 32px !important;
  color: rgba(255, 255, 255, 0.75);
}

.searchInput {
  width: 100%;
  color: #cdd3d6;
  margin: 0 !important;
  padding: 0 10px !important;
  font-size: 14px !important;
  background: transparent !important;
  border-color: transparent !important;
}

.searchInput:focus {
  box-shadow: none !important;
}

.searchInput::placeholder {
  font-size: 12px;
  text-align: end;
  color: #cdd3d6;
}

.container_hidden .searchInput {
  display: none;
}
.container .searchInput {
  display: initial;
}

.container {
  width: 100%;
  display: flex;
  border-radius: 5px;
  align-items: center;
  background: rgba(255, 255, 255, 0.12);
}
