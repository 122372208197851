.product {
    display: flex;
    align-items: center;    
    height: 100%;
    width: 100%;
  }
  
  .product + .product {
    margin-top: 18px;
  }
  
  .product-img {
    margin-right: 1em;
    width: 8vw;
    height: 15vh;
    object-fit: cover;
    border-radius: 6px
  }

  @media screen and (max-width: 940px ) {
    .product-img {
      width: 26%;
    }
  }
  
  .product-body {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
  }
  
  .actions {
    display: flex;
    width: 100%;
    align-items: space-between;
    justify-content: space-between;
  }
  
  .delete-item {
    color: #808b97;
    margin-right: 10px;
    cursor: pointer;
    font-family: 'Metropolis-bold';
    text-transform: 'uppercase';
  }
  
  .price {
    background-color: #000000;
    padding: 4px 8px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 4vw;
    font-family: 'Metropolis-bold';
    text-transform: 'uppercase';
  }
  
  .price::after {
    background-color: black;
    font-size: 8px;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    content: attr(title);
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 300ms ease-in-out opacity;
  }
  
  .price:hover::after {
    opacity: 1;
  }
  
  .select {
    position: relative;
    padding: 0 1em 0 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 0;
    font-size: 1.4rem;
    margin: 0;
    color: #808b97;
  }