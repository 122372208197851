.sideBar :global(.p-sidebar) {
  height: 100% !important;
  max-height: 100vh !important;
  width: 100% !important;
}

.sideBar :global(.p-sidebar-header) {
  display: none;
}
.sideBar .content {
  display: flex;
  position: relative;
  flex-direction: column;
}
.sideBar .header {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}

.sideBar .logo {
  height: 31px;
  max-width: 89px;
  width: fit-content;
  border-radius: 50%;
  object-fit: contain;
}

.sideBar :global(.p-sidebar-content) {
  padding: 10px 20px !important;
  position: relative;
}

.galleryContainer :global(.p-galleria-item-wrapper) {
  height: fit-content !important;
}

.galleryContainer .mainPicture img {
  width: 100%;
  height: auto;
  max-height: 250px;
  aspect-ratio: 1/1;
  border-radius: 7px;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.1);
}

.galleryContainer :global(.p-galleria-thumbnail-wrapper) {
  max-width: 25%;
}

.galleryContainer :global(.p-galleria-thumbnail-container) {
  padding: 0 5px !important;
  background-color: transparent !important;
}

.galleryContainer :global(.p-galleria-thumbnail-container) button {
  display: none;
}

.galleryContainer :global(.p-galleria-thumbnail-items-container) {
  max-height: 335px;
  height: 100% !important;
}

.galleryContainer :global(.p-galleria-thumbnail-item) {
  align-items: start;
}

.galleryContainer :global(.p-galleria-thumbnail-item-content) {
  width: 100%;
}

.galleryContainer :global(.p-galleria-thumbnail-item-content):focus {
  box-shadow: none !important;
}

.galleryContainer .thumbnailPicture {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 7px;
}

.sideBar .title {
  font-size: 24px;
  color: #f2f2f2;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 2rem;
  font-family: 'Metropolis-bold';
}

.sideBar .titleProd {
  font-size: 24px;
  margin-top: 6px;
  color: #f2f2f2;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 32px;
  font-family: 'Metropolis-bold';
}

.sideBar .subtitle {
  font-size: 18px;
  color: #f2f2f2;
  font-weight: 400;
  margin-top: 2rem;
  line-height: 122.5%;
  margin-bottom: 1rem;
  font-family: 'Metropolis';
}

.sideBar .infoName {
  color: #f2f2f2;
  font-size: 16px;
  font-weight: 700;
  line-height: 122.5%;
  margin-bottom: 2rem;
  filter: opacity(0.7);
  text-transform: capitalize;
  font-family: 'Metropolis-bold';
}

.sideBar .infoValue {
  font-size: 16px;
  text-align: end;
  color: #f2f2f2;
  font-weight: 700;
  line-height: 122.5%;
  margin-bottom: 2rem;
  font-family: 'Metropolis';
  text-transform: capitalize;
}

.sideBar .description {
  font-size: 14px;
  font-weight: 600;
  color: #ebebeb;
  line-height: 108.5%;
  margin-bottom: 1rem;
  filter: opacity(0.7);
  font-family: 'Metropolis-bold';
  display: flex;
  justify-content: space-between;
  width: 96%;
}
.sideBar .divider::before {
  filter: opacity(0.2) !important;
  width: 95%;
}

.sideBar .priceProd {
  font-size: 35px;
  color: #ebebeb;
  font-weight: bold;
  text-align: center;
  font-family: 'Metropolis-bold';
}
.sideBar .iconLeft {
  cursor: pointer;
  color: #ffffffbf;
}
.sideBar .containerInf {
  display: flex;
  flex-direction: column;
}
.containerFooter {
  padding-bottom: 4rem;
}
