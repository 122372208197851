.header {
  background: #000;
  padding: 1rem 6% 1rem 6%;
  transition: 1.7s;
}
.header_wrapper {
  /* height: 30%; */
}
.header_container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  height: 35rem;
  font-family: 'Lora', sans-serif;
  /* background: url('../assets/images/Frame.webp') no-repeat,
    url('../assets/images/Frame2.webp') no-repeat right; */
  /* height: 80%; */
}

.header_logo {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
}
.header_logo_text {
  display: none;
}
.header_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 70%, rgba(0, 0, 0, 95%)); */
}

.header_container .heading {
  font-family: 'Metropolis';
  font-size: 3.2rem;
  color: var(--white);
  font-weight: bold;
}
.header_container .description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.6rem;
}
.header_container span {
  width: 35px;
  height: 1px;
  background: #fff;
  display: inline-block;
}

.header_img {
  width: 200px;
  height: 200px;
}

.sticky_header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-bottom: 1rem;
  z-index: 5;
}

.sticky_header .header_left {
display: none;
}

.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.header_top_mobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
}

.header_left {
  display: flex;
  font-size: 2rem;
  flex-basis: 50%;
}
.header_left .header_status {
  font-family: 'Helvetica' !important;
  color: var(--text-yellow);
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 23px;
}
.header_left .header_text {
  font-size: 2rem;
  font-weight: 500;
}

.header_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  flex-basis: 50%;
}

.header_subcategories {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 1.3rem;
  overflow-x: auto;
  margin-top: 2rem;
}

.header_subcategories::-webkit-scrollbar {
  display: none;
}
.header_subcategory {
  width: 100%;
  max-width: fit-content;
  min-width: fit-content;
  background: var(--bg-gray-1);
  border: 1px solid var(--border-gray-1);
  box-shadow: 4px 4px 24px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border-radius: 24px;
  font-size: 1.6rem;
  color: var(--text-gray-5);
  font-weight: 500;
  cursor: pointer;
}
.header_subcategory_active {
  background-color: var(--white);
  font-weight: 600;
  color: var(--black);
}
.header_subcategory:focus-visible {
  outline: none;
}
.header_menu {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 50px;
  padding: 0px 2.5rem;
}
.header_menu span {
  color: var(--text-gray-4);
  font-size: 1.6rem;
  font-weight: 300;
}
.header_menu img {
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
}

/*      Search Input      */
.header_serach {
  position: relative;
  width: 50%;
  z-index: 1;
}

.search-input input {
  width: 100%;
  height: 43px;
  padding: 0 15px 0 50px;
  background: var(--bg-gray-1);
  color: var(--text-gray-4);
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 20px;
  -webkit-text-size-adjust: 100%;
}

.search-input input:focus-visible {
  outline: none;
}

.search-input input::placeholder {
  font-size: 1.6rem;
  color: var(--text-gray-4);
  font-weight: 300;
}
.search-input-icon {
  position: absolute;
  top: 12px;
  left: 80%;
  width: 3.5rem;
  height: 3.5rem;
  color: var(--text-gray-4);
}
.input:-internal-autofill-selected {
  background-color: transparent !important;
}

@media screen and (max-width: 768px) {
  .header{
    top: 0;
    right: 0;
    left: 0;
    padding-bottom: 1rem;
    z-index: 5;
  }
  .sticky_header{
    position: fixed;
    background: #000;
    padding: 1rem 6% 1rem 6%;
    transition-property: opacity;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.09, 0.7, 0.61, 1.25);
    transition: 0.5s;
    animation-name: headermobile;
    animation-duration: 2s;
    transform: translate(0, 0px);
  }
  @keyframes headermobile {
    0% {
      opacity: 0;
      transform: translate(0, -100px);
    }
    50% {
      opacity: 0.8;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
  .header_container {
    background: url('../assets/images/header_mobile.webp');
  }
  .header_overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 70%, rgba(0, 0, 0, 70%));
  }
  .header_container_content {
    background: #000;
    padding: 2%;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header_logo {
    width: 23rem;
    height: 23rem;
  }
  .header_container span {
    width: 32px;
  }
  .header_subcategories {
    gap: 0.6rem;
    margin-top: 1rem;
  }
  .header_left {
    flex-basis: unset;
    position: relative;
  }
  .header_left .header_status {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 1rem 4% 1.5rem 4%;
  }
  .header_container {
    height: 15rem;
    align-items: flex-start;
    padding-left: 4%;
    margin-bottom: 0;
  }
  .header_subcategory {
    border: none;
    background: transparent;
    padding: 8px 12px;
    color: var(--white);
    font-weight: 300;
  }
  .header_menu {
    padding: 10px 1.5rem;
  }
  .header_subcategory_active {
    font-weight: 500;
  }
  .header_left .header_status {
    text-transform: uppercase;
    color: var(--white);
  }

  .header_container {
    background: none;
  }
  .header_logo {
    margin-top: 8px;
    display: flex;
    width: 14rem;
    height: 14rem;
    align-self: center;
}
  /* .header_logo_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    font-family: 'Agrozza' !important;
  } */
  .header_container_content {
    background: #000;
    padding: 2%;
    border-radius: 50%;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header_logo_text {
    display: none;
  }
  .header_logo_left,
  .header_logo_right {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 4.8rem;
    color: var(--white);
  }
}