.containerSlider {
  padding-bottom: 20px;
}
.item {
  background: rgba(255, 255, 255, 0.05);
}
.containerSlider .imgCarrousel {
  width: 100%;
  opacity: 0.7;
  max-height: 300px;
  object-fit: cover;
}
.carouselBiblioteque :global(.carousel-indicators) {
  margin: 0;
  padding: 5px 0;
  background: linear-gradient(transparent, #1e2e5f) !important;
}
.carousel :global(.carousel-indicators) {
  margin: 0;
  padding: 5px 0;
  background: linear-gradient(transparent, black) !important;
}
