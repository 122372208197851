.sidebar {
  justify-content: center;
}

.sidebar :global(.p-sidebar) {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}
.sidebar :global(.p-sidebar-header) {
  display: none;
}

.sidebar :global(.p-sidebar-content) {
  position: relative;
  padding: 0 !important;
}

.sidebar :global(.p-sidebar-content .container) {
  padding: 0;
}

.sidebar .content {
  display: flex;
  padding: 0 0 10px;
  position: relative;
  flex-direction: column;
}

.sidebar .content .menu {
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
  border: none !important;
  box-shadow: none !important;
  transition: background 0.3s;
  font-family: 'Metropolis-bold';
  background: rgba(255, 255, 255, 0.05) !important;
}

.sidebar .content .menu:hover {
  background: rgba(255, 255, 255, 0.15) !important;
}
.sidebar .content .menu img {
  width: 20px;
  height: 20px;
  margin-right: 2rem;
}
.sidebar .divider::before {
  filter: opacity(0.2) !important;
  width: 95%;
}
.sidebar .containerFooter {
  width: 100%;
  padding-top: 3rem;
}
