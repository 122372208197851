/* Importing all CSS files */
@import url('https://fonts.googleapis.com/css2?family=Georama:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/intro-book-alt');
/*    Import CSS Files    */
@import url('./Header.css');
@import url('./ProductSection.css');
@import url('./Home.css');
@import url('./Products.css');
@import url('./SignInPhone.css');
@import url('./Responsiveness.css');

/*    Import Font Family    */
@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/helveticaneue/Helvetica\ Neue\ W01\ 66\ Medium\ It.ttf') format('woff');
}
@font-face {
  font-family: 'Agrozza';
  src: url('../assets/fonts/agrozza-demo/AgrozzaDemoRegular-test.ttf') format('woff');
}

/* @font-face {
  font-family: 'Metropolis';
  src: url('../assets/fonts/Metropolis/Metropolis-Regular.ttf') format('woff');
} */

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../assets/fonts/Metropolis-Bold.otf') format('woff');
}

@font-face {
  font-family: 'Intro';
  src: url('../assets/fonts/Intro/IntroDemoBlackCaps.otf') format('woff');
}

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Primary Variables */
:root {
  --primary: 'green';
  --text-color: #fff;
  --text-gray-1: #646464;
  --text-gray-2: #b4b4b4;
  --text-gray-3: #e6e6e6;
  --text-gray-4: #858585;
  --text-gray-5: #777777;
  --text-yellow: #deae04;
  --text-yellow-2: #c9a885;
  --border-gray-1: #646464;
  --border-gray-light: rgba(255, 255, 255, 0.1);
  --bg-gray-1: #1e1e1e;
  --bg-gray-dark: #171717;
  --bg-black: #010204;
  --black: #000;
  --white: #fff;
}

html {
  font-size: 62.5%;
}
body {
  background-color: var(--black);
  font-family: 'Georama', sans-serif;
}

.app {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.text_ellipsis_1 {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_ellipsis_custom {
  width: 95%;
  height: 95%;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.text_ellipsis_2 {
  width: 95%;
  height: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*    =====  Scroll Bar Design   =====    */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(36, 34, 34);
  outline: 1px solid rgb(87, 88, 88);
}

.Toastify__toast {
  font-size: 1.8rem;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: gray;
}
::-webkit-scrollbar-thumb:active {
  background: gray;
}
::-webkit-scrollbar-track {
  background: black;
}

/* ===================     Medi Queries    ===========================   */

/*   Laptop   */
@media screen and (max-width: 1367px) {
  html {
    font-size: 50%;
  }
}

/*   Tablet   */
@media screen and (max-width: 1100px) {
  html {
    font-size: 45%;
  }
}

/*   Tablet Medium   */
@media screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

/*   Mobile Large   */
@media screen and (max-width: 500px) {
  html {
    font-size: 50%;
  }
  body {
    font-family: 'Helvetica', Fallback, sans-serif;
  }
}

/*   Mobile Small   */
@media screen and (max-width: 400px) {
  html {
    font-size: 50%;
  }
}
