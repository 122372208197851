.sideBar :global(.p-sidebar) {
  height: 100% !important;
  max-height: 100vh !important;
  width: 100% !important;
}

.sideBar :global(.p-sidebar-header) {
  display: none;
}

.sideBar :global(.p-sidebar-content) {
  position: relative;
  padding: 10px 20px !important;
}

.sideBar .header {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}

.sideBar .iconLeft {
  cursor: pointer;
  color: #ffffffbf;
}

.sideBar .logo {
  height: 31px;
  max-width: 89px;
  width: fit-content;
  border-radius: 50%;
  object-fit: contain;
}

.sideBar .containerTitle {
  display: flex;
  align-items: center;
}

.sideBar .containerTitle .title {
  font-size: 28px;
  color: #f2f2f2;
  margin-left: 1rem;
  font-family: 'Metropolis-Bold';
}
