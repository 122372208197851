.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
  .show {
    display: flex;
  }
  
  .modalContent {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    margin: auto;
  }
  
  .modalHeader {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .modalBody {
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    color: #333;
  }
  
  .modalFooter {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .closeButton {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 0;
    transition: all 0.3s ease;
  }
  
  .noButton, .yesButton {
    background-color: #1e1c1c;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
  }
  
  .yesButton {
    background-color: #0f240f;
    color: white;
  }
  
  @media (max-width: 480px) {
    .modalContent {
        padding: 15px;
      }
    
      .closeButton {
        top: 2px;
        right: 2px;
        width: 25px;
        height: 25px;
        font-size: 14px;
      }
    
      .modalFooter button {
        padding: 8px 16px;
        font-size: 14px;
      }
  }
  