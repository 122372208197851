.conlike {
  width: 18px;
  height: 18px;
  --white: #f2f2f2;
  position: relative;
  margin: 1rem 0;
}

.conlike .like {
  opacity: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}

.conlike .checkmark {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conlike .outline,
.conlike .filled {
  fill: var(--white);
  position: absolute;
  transform: scale(1.5);
}

.conlike .filled {
  display: none;
  animation: kfr-filled 0.5s;
}

.conlike .celebrate {
  display: none;
  position: absolute;
  animation: kfr-celebrate 0.5s;
  animation-fill-mode: forwards;
}

.conlike .poly {
  fill: var(--white);
  stroke: var(--white);
}

.conlike .like:checked ~ .checkmark .filled {
  display: block;
}

.conlike .like:checked ~ .checkmark .celebrate {
  display: block;
}

@keyframes kfr-filled {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    display: none;
    transform: scale(1.2);
  }
}
