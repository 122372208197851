.basket {
    bottom: -200%;
    background-color: #fff;
    background-image: url("../../../../assets/svg/cd-icons-cart-close.svg");
    background-position: 0 center;
    background-size: 144px;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: block;
    height: 72px;
    position: fixed;
    right: 10vw;
    transition: all ease 300ms;
    text-indent: -999999px;
    width: 72px;
    z-index: 4;
  }
  
  .basket.isShown {
    bottom: 10vh;
  }
  
  .basket:hover {
    cursor: pointer;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);
  }
  
  .counter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    top: -10px;
    right: -10px;
    height: 28px;
    width: 28px;
    background: #e94b35;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50%;
    text-indent: 0;
    transition: transform 0.2s ease;
  }
  
  .counter.isShown {
    transform: scale(1);
  }
  
  .cart-container {
    background: #fff;
    border-radius: 10px;
    display: block;
    position: fixed;
    width: 0;
    height: 0;
    right: 1.8rem;
    bottom: 4.9vh;
    padding-bottom: 72px;
    text-indent: 0;
    z-index: 1;
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
  
  .cart-inner {
    padding: 21px 12px 51px;
    max-height: calc(100% - -24px);
    overflow-y: scroll;
  }
  
  .cart-footer {
    bottom: 0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.15);
    height: 72px;
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100%;
  }
  
  .checkout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    color: #fff;
    width: calc(100% - 68px);
    height: 100%;
    font-size: initial;
    border-bottom-left-radius: 5px;
    font-family: 'Metropolis-bold';
  }
  
  .cartIsOpen .basket {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    background-position: 100% center;
  }
  
  .cartIsOpen .cart-container {
    opacity: 1;
    width: 400px;
    height: 400px;
    transform-origin: bottom right;
    background-color: currentColor;
  }
  
  @media screen and (max-width: 740px ) {
    .cartIsOpen .cart-container {
      width: 92vw;
    }
  }