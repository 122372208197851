.container {
  padding-top: 2rem;
  width: 100% !important;
}
.footerContainer {
  display: flex;
  padding-left: 10px;
  padding-right: 40px;
  align-items: center;
  justify-content: space-evenly;
  color: #ffffffbf !important;
}
.restLogo {
  width: 38px;
  border-radius: 50%;
  object-fit: contain;
}

.divider::before {
  width: 95%;
  filter: opacity(0.2) !important;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer p {
  color: white;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 0px;
}
.igLogo {
  width: 16px;
}
.twLogo {
  width: 18px;
}
